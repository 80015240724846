import {
  CustomVariant,
  GiftCardProduct,
  GiftCardProductVariant,
} from '../../../../types';
import {
  CustomVariant as CustomVariantV1,
  ExpirationType,
  GiftCardProduct as GiftCardProductV1,
  PresetVariant as PresetVariantV1,
  TimePeriod,
} from '@wix/ambassador-gift-cards-v1-gift-card-product/types';
import { isUUID } from './validator';

export const mapGiftCardProductV1ToGiftCardProduct = (
  instanceId: string,
  giftCardProduct: GiftCardProductV1,
): GiftCardProduct => {
  const variants = [
    ...(giftCardProduct.presetVariants
      ? mapPresetVariantsV1ToPresentVariants(giftCardProduct.presetVariants)
      : []),
    ...(giftCardProduct.customVariant
      ? [mapCustomVariantV1ToCustomVariant(giftCardProduct.customVariant)]
      : []),
  ].filter(Boolean);

  return {
    revision: giftCardProduct.revision,
    instance_id: instanceId,
    product_id: giftCardProduct.id ?? '',
    name: giftCardProduct.name ?? '',
    description: giftCardProduct.description ?? '',
    variants,
    media: giftCardProduct.image?.id
      ? {
          id: giftCardProduct.image?.id,
          url: giftCardProduct.image?.url ?? '',
          width: giftCardProduct.image?.width ?? 0,
          height: giftCardProduct.image?.height ?? 0,
          alt_image: giftCardProduct.image?.altText ?? '',
        }
      : { id: '', url: '', width: 0, height: 0, alt_image: '' },
    months_until_expired: giftCardProduct.relativeExpirationDate?.value ?? null,
    created_at: giftCardProduct.createdDate ?? '',
    updated_at: giftCardProduct.updatedDate ?? '',
    deleted_at: '',
  };
};

const mapCustomVariantV1ToCustomVariant = (
  variant: CustomVariantV1,
): CustomVariant => {
  return {
    min: variant.minValue ? Number(variant.minValue.amount) : null,
    max: variant.maxValue ? Number(variant.maxValue.amount) : null,
    media: variant.image?.id
      ? {
          id: variant.image?.id,
          url: variant.image?.url ?? '',
          width: variant.image?.width ?? 0,
          height: variant.image?.height ?? 0,
          alt_image: variant.image?.altText ?? '',
        }
      : undefined,
    type: 'custom',
    active: true,
  };
};

const mapPresetVariantsV1ToPresentVariants = (
  presetVariant: PresetVariantV1[],
): GiftCardProductVariant[] => {
  return presetVariant.filter(Boolean).map((variant: PresetVariantV1) => ({
    id: variant.id ?? '',
    price: Number(variant.price?.amount),
    value: Number(variant.value?.amount),
    media: variant.image?.id
      ? {
          id: variant.image?.id,
          url: variant.image?.url ?? '',
          width: variant.image?.width ?? 0,
          height: variant.image?.height ?? 0,
          alt_image: variant.image?.altText ?? '',
        }
      : undefined,
    type: 'preset',
    active: true,
  }));
};

export const mapGiftCardProductToGiftCardProductV1 = (
  productData: GiftCardProduct,
): GiftCardProductV1 => {
  const customVariants = productData.variants.find(
    (variant) => variant.type === 'custom' && variant.active === true,
  ) as CustomVariant;
  const presetVariants = productData.variants.filter(
    (variant) => variant.type === 'preset' && variant.active === true,
  ) as GiftCardProductVariant[];

  return {
    revision: productData.revision,
    relativeExpirationDate: productData.months_until_expired
      ? { value: productData.months_until_expired, period: TimePeriod.MONTHS }
      : undefined,
    expirationType: productData.months_until_expired
      ? ExpirationType.RELATIVE
      : ExpirationType.NONE,
    id: productData.product_id,
    name: productData.name,
    description: productData.description,
    image: productData.media.id
      ? {
          id: productData.media.id,
          url: productData.media.url,
          width: productData.media.width,
          height: productData.media.height,
          altText: productData.media.alt_image,
        }
      : undefined,
    presetVariants: presetVariants
      ? presetVariants.map(
          (variant: GiftCardProductVariant): PresetVariantV1 => ({
            id: isUUID(variant.id) ? variant.id : undefined,
            price: {
              amount: String(variant.price),
            },
            value: {
              amount: String(variant.value),
            },
            image: variant.media?.id
              ? {
                  id: variant.media?.id,
                  url: variant.media?.url,
                  width: variant.media?.width,
                  height: variant.media?.height,
                  altText: variant.media?.alt_image,
                }
              : undefined,
          }),
        )
      : undefined,
    customVariant: customVariants
      ? {
          minValue: customVariants.min
            ? {
                amount: String(customVariants.min),
              }
            : undefined,
          maxValue: customVariants.max
            ? {
                amount: String(customVariants.max),
              }
            : undefined,
          image: customVariants.media?.id
            ? {
                id: customVariants.media?.id,
                url: customVariants.media?.url,
                width: customVariants.media?.width,
                height: customVariants.media?.height,
                altText: customVariants.media?.alt_image,
              }
            : undefined,
        }
      : undefined,
  };
};
