// assertString.ts
function assertString(input: unknown): void {
  const isString = typeof input === 'string' || input instanceof String;

  if (!isString) {
    let invalidType: string = typeof input;
    if (input === null) {
      invalidType = 'null';
    } else if (invalidType === 'object' && input !== null) {
      invalidType = (input as object).constructor.name;
    }
    throw new TypeError(`Expected a string but received a ${invalidType}`);
  }
}

type UUIDVersion = 1 | 2 | 3 | 4 | 5 | 7 | 'all';

const uuid: Record<UUIDVersion | string, RegExp> = {
  1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  7: /^[0-9A-F]{8}-[0-9A-F]{4}-7[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
};

export const isUUID = (str: string, version?: UUIDVersion | null): boolean => {
  assertString(str);
  const pattern = uuid[version as UUIDVersion] || uuid.all;
  return pattern.test(str);
};
