import {
  ControllerParams,
  IHttpClient,
  ControllerFlowAPI,
  IWixWindow,
  ISiteApis,
} from '@wix/yoshi-flow-editor';
import { Checkout, ApiChannelType } from '@wix/ambassador-checkout/http';
import { Checkout as ICheckout } from '@wix/ambassador-checkout/types';
import { makeAutoObservable } from 'mobx';
import APP from '../../.application.json';
import {
  GiftCardProduct,
  GiftCardCatalogOptions,
  IPurchaseOptions,
  GiftCardProductVariant,
} from '../../../../types';
import { ERROR_CODES } from '../consts';

const BASE_URL = '/ecom';
const checkoutService = Checkout(BASE_URL).CheckoutService();
const STORES_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

export class GiftCardCheckoutService {
  httpClient: IHttpClient;
  instance: string;
  translationsConfig: ControllerFlowAPI['translations']['config'];
  environment: ControllerFlowAPI['environment'];
  flowAPI: ControllerFlowAPI;
  bi: ControllerFlowAPI['bi'];
  wixWindow: IWixWindow;
  site: ISiteApis;

  constructor(controllerParams: ControllerParams) {
    makeAutoObservable(this);
    this.httpClient = controllerParams.flowAPI.httpClient;
    this.instance = controllerParams.controllerConfig.appParams.instance;
    this.translationsConfig = controllerParams.flowAPI.translations.config;
    this.environment = controllerParams.flowAPI.environment;
    this.bi = controllerParams.flowAPI.bi;
    this.wixWindow = controllerParams.controllerConfig.wixCodeApi.window;
    this.site = controllerParams.controllerConfig.wixCodeApi.site;
    this.flowAPI = controllerParams.flowAPI;
  }

  async createGiftCardCheckout(
    product_id: GiftCardProduct['product_id'],
    purchase_options: GiftCardCatalogOptions,
    add_new_catalog_option: boolean = false,
  ): Promise<{
    checkout: ICheckout | undefined;
    errors: { noPremium: boolean };
  }> {
    let checkout: ICheckout | undefined,
      noPremium = false;
    try {
      const result = await checkoutService({
        Authorization: this.instance,
      }).createCheckout({
        lineItems: [
          {
            quantity: purchase_options.quantity,
            catalogReference: {
              appId: APP.appDefinitionId,
              catalogItemId: product_id,
              options: {
                ...purchase_options,
                ...(add_new_catalog_option
                  ? { wixGiftCardsAppNewCatalog: true }
                  : {}),
              },
            },
          },
        ],
        channelType: ApiChannelType.WEB,
        checkoutInfo: {},
      });

      checkout = result.checkout;
    } catch (err: any) {
      if (
        err &&
        err?.response?.details?.applicationError?.code ===
          ERROR_CODES.SiteMustAcceptPayments
      ) {
        noPremium = true;
      }
    }

    return { checkout, errors: { noPremium } };
  }

  reportCheckout = async ({
    productId,
    checkoutId,
    purchase_options,
    price,
    currencyCode,
    type,
  }: {
    productId: GiftCardProduct['product_id'];
    checkoutId: ICheckout['id'];
    purchase_options: IPurchaseOptions;
    price: GiftCardProductVariant['price'];
    currencyCode: string;
    type: string;
  }) => {
    if (!this.environment.isSSR) {
      this.bi?.report({
        evid: 20,
        src: 125,
        endpoint: 'gc-client-sf',
        params: {
          productId,
          checkoutId,
          priceOption: price,
          currency: currencyCode,
          hasDeliveryDate: !!purchase_options.deliverAt,
          deliveryDate: purchase_options.deliverAt,
          editorMode: this.environment.isMobile ? 'mobile' : 'desktop',
          quantity: purchase_options.quantity,
          type,
          redirectType: 'checkout',
        },
      });
    }
  };

  async navigateToCheckout(checkoutId: string) {
    const publicApi = await this.site.getPublicAPI(STORES_APP_DEF_ID);

    await publicApi.navigate.toCheckout({
      checkoutId,
    });
  }

  async getWixCheckoutURL(checkoutId: ICheckout['id']) {
    const checkoutURL = await checkoutService({
      Authorization: this.instance,
    }).getWixCheckoutURL({
      id: checkoutId,
    });
    return checkoutURL;
  }
}
