import { ControllerParams, IHttpClient } from '@wix/yoshi-flow-editor';
import { makeAutoObservable } from 'mobx';
import { GiftCardProduct, VariantsOptions } from '../../../../types';
import {
  createGiftCardProduct,
  isInWixGiftCardsAppPopulation,
  queryGiftCardProducts,
} from '@wix/ambassador-gift-cards-v1-gift-card-product/http';
import {
  mapGiftCardProductV1ToGiftCardProduct,
  mapGiftCardProductToGiftCardProductV1,
} from '../utils/giftCardProductMapper';
import { PanoramaTransactions } from '../consts';
import staticDefaultTemplate from './staticDefaultTemplate';

interface GetProductResponse {
  gift_card_product: GiftCardProduct;
}

export class GiftCardService {
  httpClient: IHttpClient;
  instance: string;
  panoramaClient: ControllerParams['flowAPI']['panoramaClient'];
  translate: ControllerParams['flowAPI']['translations']['t'];

  constructor(controllerParams: ControllerParams) {
    makeAutoObservable(this);
    this.translate = controllerParams.flowAPI.translations.t;
    this.httpClient = controllerParams.flowAPI.httpClient;
    this.instance = controllerParams.controllerConfig.appParams.instance;
    this.panoramaClient = controllerParams.flowAPI.panoramaClient;
  }

  async getIsInWixGiftCardsAppPopulation() {
    try {
      const {
        data: { shouldUseNewGiftCardServices },
      } = await this.httpClient.request(isInWixGiftCardsAppPopulation({}));
      return shouldUseNewGiftCardServices ?? false;
    } catch (error) {
      this.panoramaClient?.errorMonitor().reportError(error as Error);
      console.error(
        'An error occurred while checking if new Gift Card services should be used:',
        error,
      );
    }
  }

  async getProduct({
    currency,
  }: {
    currency?: string | null;
  }): Promise<GiftCardProduct> {
    const requestUrl = 'https://wix.rise.ai/catalog/templates';
    const requestUrlWithCurrency = `${requestUrl}?currency=${currency}`;

    const {
      data: { gift_card_product },
    } = await this.httpClient.request<GetProductResponse>({
      url: 'https://www.wix.com/_serverless/rise-proxy/rise',
      method: 'post',
      headers: {
        Authorization: this.instance,
      },
      disableWixHeaders: true,
      data: {
        riseRequest: {
          url: currency ? requestUrlWithCurrency : requestUrl,
          method: 'get',
          data: {},
        },
      },
    });

    // inctead of script : for existing stores
    const variantsWithType = gift_card_product.variants.map(
      (variant: VariantsOptions) => ({
        ...variant,
        type: variant.type || 'preset',
        active: variant.active === undefined ? true : variant.active,
      }),
    ) as VariantsOptions[];

    return { ...gift_card_product, variants: variantsWithType };
  }

  async getGiftCardProductV1() {
    try {
      this.panoramaClient
        ?.transaction(PanoramaTransactions.giftCardProductsExist)
        .start();

      const {
        data: { giftCardProducts },
      } = await this.httpClient.request(queryGiftCardProducts({}));

      const product = giftCardProducts?.[0];

      if (product) {
        this.panoramaClient
          ?.transaction(PanoramaTransactions.giftCardProductsExist)
          .finish();
      }

      return (
        product && mapGiftCardProductV1ToGiftCardProduct(this.instance, product)
      );
    } catch (error) {
      this.panoramaClient?.errorMonitor().reportError(error as Error);
      console.error(
        'An error occurred while fetching Gift Card product:',
        error,
      );
    }
  }

  async getDefaultTemplate() {
    const nameDefaultKey = 'giftCards.editGiftCard.infoCard.nameDefault';
    const descriptionDefaultKey =
      'giftCards.editGiftCard.infoCard.descriptionDefault';

    return {
      ...staticDefaultTemplate,
      name: this.translate(nameDefaultKey),
      description: this.translate(descriptionDefaultKey),
    } as GiftCardProduct;
  }

  async createGiftCardProductV1() {
    this.panoramaClient
      ?.transaction(PanoramaTransactions.giftCardCreateDefaultProduct)
      .start();
    const productData = mapGiftCardProductToGiftCardProductV1(
      await this.getDefaultTemplate(),
    );
    const {
      data: { giftCardProduct },
    } = await this.httpClient.request(
      createGiftCardProduct({ giftCardProduct: productData }),
    );
    this.panoramaClient
      ?.transaction(PanoramaTransactions.giftCardCreateDefaultProduct)
      .finish();
    return giftCardProduct;
  }

  async getOrCreateGiftCardProductV1() {
    let product: any;
    try {
      const {
        data: { giftCardProducts },
      } = await this.httpClient.request(queryGiftCardProducts({}));

      product = giftCardProducts?.[0];

      if (!product) {
        product = await this.createGiftCardProductV1();
      }

      return (
        product && mapGiftCardProductV1ToGiftCardProduct(this.instance, product)
      );
    } catch (error) {
      this.panoramaClient?.errorMonitor().reportError(error as Error);
      console.error(
        'An error occurred while fetching Gift Card product:',
        error,
      );
    }
  }

  async getCurrencyCode(): Promise<string> {
    const getCurrencyCodeRequest = await this.httpClient.request<string>({
      url: 'https://www.wix.com/_serverless/rise-proxy/wix',
      method: 'post',
      headers: {
        Authorization: this.instance,
      },
      disableWixHeaders: true,
      data: {
        wixRequest: {
          serviceRequest: {
            entity: 'SettingsReaderService',
            method: 'getCurrency',
            params: {},
          },
        },
      },
    });

    return getCurrencyCodeRequest.data;
  }
}
