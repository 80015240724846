const staticDefaultTemplate = {
  variants: [
    {
      id: '1',
      value: 25,
      price: 25,
      type: 'preset',
      active: true,
    },
    {
      id: '2',
      value: 50,
      price: 50,
      type: 'preset',
      active: true,
    },
    {
      id: '3',
      value: 100,
      price: 100,
      type: 'preset',
      active: true,
    },
    {
      id: '4',
      value: 150,
      price: 150,
      type: 'preset',
      active: true,
    },
    {
      id: '5',
      value: 200,
      price: 200,
      type: 'preset',
      active: true,
    },
  ],
  media: {
    id: 'e90a2a_c3de803545f340df9dd4aa88a72e0718~mv2.png',
    url: 'https://static.wixstatic.com/media/e90a2a_c3de803545f340df9dd4aa88a72e0718~mv2.png',
    width: 600,
    height: 600,
  },
};

export default staticDefaultTemplate;
